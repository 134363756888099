<template>
  <section class="mt-xs mb-xl" v-if="tabs?.length" data-test-id="heroTabulatedBanner">
    <div class="relative h-[480px] lg:h-[575px]">
      <picture v-if="!sliderReady && (preloadedDesktopImage || preloadedMobileImage)">
        <source media="(min-width: 768px)" :srcset="preloadedDesktopImage" type="image/avif" />
        <source media="(max-width: 767px)" :srcset="preloadedMobileImage" type="image/avif" />
        <img
          :src="preloadedDesktopImage || preloadedMobileImage"
          :alt="tabs[0]?.description || ''"
          loading="eager"
          fetchpriority="high"
          ref="firstPreloadImg"
          class="absolute -z-10 top-none left-none object-cover w-full h-[480px] lg:h-[575px]"
          :class="{ 'object-[20%]': shouldShowDesktopImage, 'object-top': !shouldShowDesktopImage }"
        />
      </picture>

      <client-only>
        <Slider
          :draggable="true"
          :autoplay="false"
          :slides-per-view="1"
          :total-slides="tabs?.length"
          :items="tabs"
          @slide-change="changeTabIndex"
          @swiper="onSwiper"
          data-test-id="heroTabulatedSlider"
        >
          <swiper-slide v-for="(tab, index) in tabs" :key="index" class="m-auto" data-test-id="heroTabulatedSlide">
            <div class="relative h-[480px] lg:h-[575px]">
              <NuxtPicture
                v-if="shouldShowDesktopImage && tab.desktopImage"
                :src="tab.desktopImage"
                quality="85"
                :alt="tab.description"
                :loading="index === 0 ? 'eager' : 'lazy'"
                :fetch-priority="index === 0 ? 'high' : 'auto'"
                data-fs="homepageBannerImage"
                data-test-id="heroTabulatedImageDesktop"
                :ref="index === 0 ? setFirstSlideDesktopImg : undefined"
                @load="index === 0 ? onSliderImageLoad : undefined"
                :img-attrs="{
                  class:
                    'hidden sm:block absolute -z-10 top-none left-none object-cover object-[20%] w-full h-[480px] lg:h-[575px]',
                }"
              />
              <NuxtPicture
                v-if="!shouldShowDesktopImage && tab.mobileImage"
                :src="tab.mobileImage"
                quality="75"
                :alt="tab.description"
                :loading="index === 0 ? 'eager' : 'lazy'"
                :fetch-priority="index === 0 ? 'high' : 'auto'"
                data-test-id="homepageBannerImageMobile"
                :ref="index === 0 ? setFirstSlideMobileImg : undefined"
                @load="index === 0 ? onSliderImageLoad : undefined"
                :img-attrs="{
                  class: 'sm:hidden absolute -z-10 top-none left-none object-cover object-top w-full h-[480px]',
                }"
              />
              <div v-if="tab.isDarkOverlay" class="z-0 absolute top-none w-full h-full bg-charcoal-darker opacity-40" />
              <div
                class="z-20 w-full absolute transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-center"
              >
                <h1 class="mb-2xs text-[36px] md:text-[4rem] leading-[45px] md:leading-7xl tracking-tighter font-semi">
                  {{ tab.title }}
                </h1>
                <p class="mb-lg font-semi text-xl md:text-[30px] leading-[28px] md:leading-4xl">
                  {{ tab.description }}
                </p>
                <NuxtLink v-if="tab.buttonLink && tab.buttonText" :to="tab.buttonLink" data-fs="homepageBannerLink">
                  <Button variant="primary" size="base" class="mx-auto" v-if="tab.buttonText">
                    {{ tab.buttonText }}
                  </Button>
                </NuxtLink>
              </div>
            </div>
          </swiper-slide>
        </Slider>
      </client-only>
    </div>

    <Stack
      direction="row"
      align="center"
      justify="center"
      class="mt-sm md:mt-2xs"
      gap="2xs"
      v-if="tabs.length > 1"
      data-fs="heroTabulatedCarousel"
      data-test-id="heroTabulatedCarousel"
    >
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        @click="changeTabIndex(index)"
        :data-test-id="`heroTabulatedTab-${index}`"
        :style="{
          backgroundImage: tab.tabImage && shouldShowDesktopImage ? `url(${tab.tabImage})` : 'none',
        }"
        :class="[
          'md:rounded-none md:min-w-[250px] lg:min-w-[350px] p-[5px] md:py-xs md:px-xs lg:p-sm ',
          tab.tabImage
            ? 'bg-cover bg-[70%] md:bg-[50%] bg-no-repeat md:min-h-[105px] md:w-[377px] rounded-round md:rounded-xs'
            : 'rounded-round',
          tab.tabImage && (active === index ? 'opacity-100' : 'opacity-70 hover:opacity-100'),
          active === index
            ? 'bg-charcoal-default text-white font-semi'
            : 'bg-mkm-yellow-default hover:bg-mkm-yellow-dark text-charcoal-default',
        ]"
      >
        <p class="hidden md:block md:text-base leading-lg lg:text-md font-semi" v-if="!tab.tabImage">{{ tab.title }}</p>
        <p class="hidden md:block md:text-sm lg:text-base leading-md" v-if="!tab.tabImage">{{ tab.tabDescription }}</p>
      </button>
    </Stack>
  </section>
</template>

<script setup lang="ts">
import { SwiperSlide } from "swiper/vue";
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useHeroBanner } from "../../../composables/UseContent/useHeroBanner";

const img = useImage();

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const tabs = computed(() => useHeroBanner(page.value, component.value).tabs);
const active = ref(0);
const swiperRef = ref<any>(null);
const sliderReady = ref(false);
const firstPreloadImg = ref<HTMLImageElement | null>(null);

const isDesktopEligible = (): boolean => {
  return typeof window !== "undefined" && window.innerWidth >= 768;
};

const shouldShowDesktopImage = ref(isDesktopEligible());

const firstSlideDesktopImg = ref<HTMLImageElement | null>(null);
const firstSlideMobileImg = ref<HTMLImageElement | null>(null);
const sliderLoadTimeout = ref<number | null>(null);

const setFirstSlideDesktopImg = (element: Element | ComponentPublicInstance | null) => {
  if (!element) return;

  if ("$el" in element && element.$el instanceof HTMLImageElement) {
    firstSlideDesktopImg.value = element.$el;
  } else if (element instanceof HTMLImageElement) {
    firstSlideDesktopImg.value = element;
  }
};

const setFirstSlideMobileImg = (element: Element | ComponentPublicInstance | null) => {
  if (!element) return;

  if ("$el" in element && element.$el instanceof HTMLImageElement) {
    firstSlideMobileImg.value = element.$el;
  } else if (element instanceof HTMLImageElement) {
    firstSlideMobileImg.value = element;
  }
};

const onSliderImageLoad = () => {
  sliderLoadTimeout.value = window.setTimeout(() => {
    sliderReady.value = true;
  }, 100);
};

onBeforeUnmount(() => {
  if (sliderLoadTimeout.value !== null) {
    clearTimeout(sliderLoadTimeout.value);
  }
});

onMounted(() => {
  const isFirstImageLoaded = shouldShowDesktopImage.value
    ? firstSlideDesktopImg.value?.complete
    : firstSlideMobileImg.value?.complete;

  if (isFirstImageLoaded) {
    onSliderImageLoad();
  }

  window.addEventListener("resize", () => {
    shouldShowDesktopImage.value = isDesktopEligible();
  });
});

const changeTabIndex = (index: any) => {
  if (typeof index === "number") {
    active.value = index;
    swiperRef.value.slideTo(index);
  } else if (typeof index === "object") {
    active.value = index.activeIndex;
  }
};

const onSwiper = (swiper: any) => {
  swiperRef.value = swiper;
};

const preloadedDesktopImage = img(tabs.value[0]?.desktopImage, { width: 3072, format: "avif", quality: 25 });
const preloadedMobileImage = img(tabs.value[0]?.mobileImage, { width: 1024, format: "avif", quality: 15 });

useHead({
  link: [
    {
      rel: "preload",
      as: "image",
      href: preloadedDesktopImage,
      imagesizes: "(min-width: 768px) 100vw, 0px",
      fetchpriority: "high",
    },
    {
      rel: "preload",
      as: "image",
      href: preloadedMobileImage,
      imagesizes: "(max-width: 767px) 100vw, 0px",
      fetchpriority: "high",
    },
  ],
});
</script>
